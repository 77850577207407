<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab
        :title-link-class="'icon-panel-home'"
        @click="openMenuPanel"
      >
        <template #title>
          <feather-icon
            icon="ArrowLeftIcon"
            size="19"
          />
        </template>
      </b-tab>
      <b-tab
        title="Arrecadação"
        active
      >
        <iframe
          width="100%"
          height="800"
          src="https://app.powerbi.com/view?r=eyJrIjoiNjY4YjE3YzUtYzkzNi00OGMwLWEzMDQtN2FiNmYwODRhNzg3IiwidCI6Ijg1NDczOTk4LTFmODEtNDAxMS1iYzk3LTg3YWUwNGU2MTIwNCJ9&pageName=ReportSection449c31086baf0ebb11da"
          frameborder="0"
          allowFullScreen="true"
        />
      </b-tab>
      <b-tab
        title="Inadimplência"
        lazy
      >
        <iframe
          width="100%"
          height="800"
          src="https://app.powerbi.com/view?r=eyJrIjoiNGE1MTk5ODMtZmE2Yy00YWRmLTk0ODgtMGYzMGY5MDVlN2YzIiwidCI6Ijg1NDczOTk4LTFmODEtNDAxMS1iYzk3LTg3YWUwNGU2MTIwNCJ9&pageName=ReportSection8f61daa709794b8e5eab"
          frameborder="0"
          allowFullScreen="true"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'

export default {
  data() {
    return {}
  },
  beforeCreate() {
    store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'hidden' })
  },
  beforeRouteLeave(to, from, next) {
    store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'floating' })
    next()
  },
  methods: {
    openMenuPanel() {
      router.push({ name: 'panel' })
    },
  },
}
</script>
<style>
  [dir] .nav-pills .nav-link {
    padding: 0.3rem 1.5rem;
    border: 1px solid transparent;
  }
  .icon-panel-home {
    padding: 0.1rem 0.8rem 0rem 0rem !important
  }
</style>
